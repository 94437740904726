import React from "react";
import SEO from "../components/SEO/SEO";
import {Layout} from "../components/layout/layout";

import "./privacy-policy.scss";

const PrivacyPolicyPage = () => {
  return (
    <main>
      <SEO
        title="Privacy Policy"
        pathname="/privacy-policy"
      />
      <Layout page="privacy-policy">
        <div className="my-privacy">
            Effective date: February 10, 2019
            <br/><br/>
            Miami Yachting LLC (“us”, “we”, or “our”) operates the <a
            href="https://www.miamiyachting.net/">https://www.miamiyachting.net</a> website (the “Service”).
            <br/><br/>
            This page informs you of our policies regarding the collection, use, and disclosure of personal data
            when you use our Service and the choices you have associated with that data. Our Privacy Policy for
            Miami Yachting LLC is managed through Free Privacy Policy.
            <br/><br/>
            We use your data to provide and improve the Service. By using the Service, you agree to the
            collection and use of information in accordance with this policy. Unless otherwise defined in this
            Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and
            Conditions, accessible from <a
            href="https://www.miamiyachting.net/">https://www.miamiyachting.net</a>
            <h2>Information Collection And Use</h2>
            We collect several different types of information for various purposes to provide and improve
            our Service to you.
            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            While using our Service, we may ask you to provide us with certain personally identifiable
            information that can be used to contact or identify you (“Personal Data”). Personally
            identifiable information may include, but is not limited to:
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
            </ul>

            <h3>Usage Data</h3>

            We may also collect information how the Service is accessed and used (“Usage Data”). This
            Usage Data may include information such as your computer’s Internet Protocol address (e.g.
            IP address), browser type, browser version, the pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.


            <h3>Tracking &amp; Cookies Data</h3>
            We use cookies and similar tracking technologies to track the activity on our Service and
            hold certain information.
            <br/><br/>
            Cookies are files with small amount of data which may include an anonymous unique
            identifier. Cookies are sent to your browser from a website and stored on your device.
            Tracking technologies also used are beacons, tags, and scripts to collect and track
            information and to improve and analyze our Service.
            <br/><br/>
            You can instruct your browser to refuse all cookies or to indicate when a cookie is
            being sent. However, if you do not accept cookies, you may not be able to use some
            portions of our Service.
            <br/><br/>
            Examples of Cookies we use:
            <ul>
              <li>Session Cookies. We use Session Cookies to operate our Service.</li>
              <li>Preference Cookies. We use Preference Cookies to remember your preferences and
                various settings.
              </li>
              <li>Security Cookies. We use Security Cookies for security purposes.</li>
            </ul>

            <h2>Use of Data</h2>
            Miami Yachting LLC uses the collected data for various purposes:
            <ul>
              <li>To provide and maintain the Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features of our Service when you
                choose to do so
              </li>
              <li>To provide customer care and support</li>
              <li>To provide analysis or valuable information so that we can improve the
                Service
              </li>
              <li>To monitor the usage of the Service</li>
              <li>To detect, prevent and address technical issues</li>
            </ul>

            <h2>Transfer Of Data</h2>
            Your information, including Personal Data, may be transferred to — and maintained
            on — computers located outside of your state, province, country or other
            governmental jurisdiction where the data protection laws may differ than those
            from your jurisdiction.
            <br/><br/>
            If you are located outside United States and choose to provide information to
            us, please note that we transfer the data, including Personal Data, to United
            States and process it there.
            <br/><br/>
            Your consent to this Privacy Policy followed by your submission of such
            information represents your agreement to that transfer.
            <br/><br/>
            Miami Yachting LLC will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy Policy and
            no transfer of your Personal Data will take place to an organization or a
            country unless there are adequate controls in place including the security
            of your data and other personal information.
            <h2>Disclosure Of Data</h2>
            <h3>Legal Requirements</h3>
            Miami Yachting LLC may disclose your Personal Data in the good faith
            belief that such action is necessary to:
            <ul>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Miami Yachting LLC
              </li>
              <li>To prevent or investigate possible wrongdoing in connection with the
                Service
              </li>
              <li>To protect the personal safety of users of the Service or the
                public
              </li>
              <li>To protect against legal liability</li>
            </ul>

            <h2>Security Of Data</h2>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially acceptable
            means to protect your Personal Data, we cannot guarantee its absolute
            security.
            <h2>Service Providers</h2>
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
            <br/><br/>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to
            disclose or use it for any other purpose.
            <h2>Analytics</h2>
            We may use third-party Service Providers to monitor and
            analyze the use of our Service.
            <br/><br/>
            Google AnalyticsGoogle Analytics is a web analytics service
            offered by Google that tracks and reports website traffic.
            Google uses the data collected to track and monitor the use
            of our Service. This data is shared with other Google
            services. Google may use the collected data to contextualize
            and personalize the ads of its own advertising network.You
            can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google
            Analytics opt-out browser add-on. The add-on prevents the
            Google Analytics JavaScript (ga.js, analytics.js, and dc.js)
            from sharing information with Google Analytics about visits
            activity.For more information on the privacy practices of
            Google, please visit the Google Privacy &amp; Terms web
            page: <a
            href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
            <h2>Links To Other Sites</h2>
            Our Service may contain links to other sites that are
            not operated by us. If you click on a third party link,
            you will be directed to that third party’s site. We
            h3ly advise you to review the Privacy Policy of
            every site you visit.
            <br/><br/>
            We have no control over and assume no responsibility
            for the content, privacy policies or practices of any
            third party sites or services.
            <h2>Children’s Privacy</h2>
            Our Service does not address anyone under the age
            of 18 (“Children”).
            <br/><br/>
            We do not knowingly collect personally
            identifiable information from anyone under the
            age of 18. If you are a parent or guardian and
            you are aware that your Children has provided us
            with Personal Data, please contact us. If we
            become aware that we have collected Personal
            Data from children without verification of
            parental consent, we take steps to remove that
            information from our servers.
            <h2>Changes To This Privacy
              Policy</h2>
            We may update our Privacy Policy from time
            to time. We will notify you of any changes
            by posting the new Privacy Policy on this
            page.
            <br/><br/>
            We will let you know via email and/or a
            prominent notice on our Service, prior to
            the change becoming effective and update
            the “effective date” at the top of this
            Privacy Policy.
            <br/><br/>
            You are advised to review this Privacy
            Policy periodically for any changes.
            Changes to this Privacy Policy are
            effective when they are posted on this
            page.
            <h2>Contact Us</h2>
            If you have any questions about this
            Privacy Policy, please contact us:
            <ul>
              <li>By email: <a
                href="mailto:info@miamiyachting.net">info@miamiyachting.net</a>
              </li>
              <li>By visiting this page on our
                website <a
                  href="https://www.miamiyachting.net/">https://www.miamiyachting.net</a>
              </li>
            </ul>
        </div>
      </Layout>
    </main>
  );
}

export default PrivacyPolicyPage;